/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e12bf168-88c9-4982-ba85-2f60c1f0ce38",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_mAfr5xrTC",
    "aws_user_pools_web_client_id": "6cjfj75kj7fead0f0gjaaocopt",
    "oauth": {}
};


export default awsmobile;
